import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import Staff from "../shared/about/Staff"
import HeadingM from "../shared/HeadingM"

const query = graphql`
  {
    allContentfulDoStaff(sort: { fields: order, order: ASC }) {
      nodes {
        role
        name
        comment {
          comment
        }
        image {
          gatsbyImageData
        }
      }
    }
  }
`

const AboutStaff = () => {
  const data = useStaticQuery(query)
  const items = data.allContentfulDoStaff.nodes.map(item => ({
    image: getImage(item.image),
    name: item.name,
    position: item.role,
    comment: item.comment.comment,
  }))
  return (
    <div>
      <HeadingM title="スタッフ紹介" target="do" />
      <Staff items={items} target="do" />
    </div>
  )
}

export default AboutStaff
