import React from "react"
import AboutGallery from "../../components/do/AboutGallery"
import AboutInfo from "../../components/do/AboutInfo"
import AboutMap from "../../components/do/AboutMap"
import AboutStaff from "../../components/do/AboutStaff"
import HeadingL from "../../components/shared/HeadingL"
import Layout from "../../components/do/Layout"
import SEO from "../../components/Seo"
import { PAGE } from "../../constants"

const about = () => (
  <Layout>
    <SEO title={PAGE.title.about} siteTitle="Do" />
    <HeadingL title={PAGE.title.about} target="do" />
    <AboutInfo />
    <AboutMap />
    <AboutStaff />
    <AboutGallery />
  </Layout>
)

export default about
